import React from 'react';
import Layout from "../../components/layout";
import {StaticImage} from "gatsby-plugin-image";

class KarateInstructorsNotHighlyRegarded extends React.Component {
    render() {
        return (
            <Layout>
                <h1>"Karate Instructors not highly regarded"</h1>
                <StaticImage src="../../images/karateinstructors/ResizedImage600408-2264694-orig.jpg"
                             alt="Karate Instructors not highly regarded"/>
            </Layout>);
    }
}

export default KarateInstructorsNotHighlyRegarded;
